import React from "react";
import Header from "../../components/Landers/Header";
import Banner from "../../components/Landers/Banner";
import BrandsTemp from "../../components/Landers/BrandsTemp";
import AllCheck from "../../components/Landers/AllCheck";
import SolvingVerify from "../../components/Landers/SolvingVerify";
import Explore from "../../components/Landers/Explore";
import GetStarted from "../../components/Landers/GetStarted";
import SEO from "../../components/Landers/SEO";
import Testimonials from "../../components/Landers/Testimonials";
import Footer from "../../components/Landers/Footer";
import DataInfo from "../../components/Data";
import styles from "../../components/Landers/landers.module.scss";
import CustomizedSolutions from "../../components/Landers/CustomizedSolutions";

const solutions = [
  {
    h5: "Optimize your Verification \nProcess Effortlessly",
    p:
      "Convert qualified candidates faster by screening them based on the position you are filling. Provide equal ground for all who could be a great fit for the role you’re filling for.",
  },
  {
    h5: "Deliver Friction-free Candidate \nExperience",
    p:
      "Offer your Candidates a smooth, quick, and memorable experience. Our all-new candidate portal is built to assist them through every step of the process - from tracking status to reaching out to our dedicated support team in case of queries.",
  },
  {
    h5: "Compliant with Top Rules and \nRegulations",
    p:
      "SpringVerify is compliant with ISO 27001:9000, PCI, and more. You can rest assured that the candidates you verify are in par with the industrial standards. ",
  },
];

const steps = [
  {
    h5: "Request Employment Verification",
    p:
      "Initiate the background check process for a candidate for whom you need to run employment screening.",
  },
  {
    h5: "Run Employment Checks",
    p: `SpringVerify uses Knowledge-Based Authentication (KBA) to verify the candidate’s history of employment,
      designation, duration, and more. It also checks if they are registered with statutory bodies like MCA/ROC, TAN, PF, etc.`,
  },
  {
    h5: "Get real-time updates",
    p:
      "Get notified about your candidate’s verification status with real time updates. Make informed decisions by hiring the right person for the role.",
  },
];

const testimonials = [
  {
    name: "Rupasi M",
    company: "Datarynx Pvt. Ltd.",
    p: `SpringVerify is our go-to app for all our background checks.
			I'm in love with its flexibility, time-bound closures,
			and a support team that is always ready to answer our queries 24x7.`,
  },
  {
    name: "Ravindra M",
    company: "Rupeezen",
    p: `I love the simplicity and clean UI of SpringVerify. As the checks are digitized, there's no need for human intervention.
			This has helped us to quicken the onboarding time for our fresh hires.`,
  },
  {
    name: "Maryam J",
    company: "Resolvit Management and Technology Consulting India Pvt. Ltd.",
    p: `SpringVerify is very simple, reliable, and straightforward. The UX is awesome, checks are fast and most importantly all
			my queries are answered promptly without me having to wait for long.`,
  },
];

export default () => (
  <div className={styles.solutions}>
    <SEO
      title="Background Screening Service for Talent Acquisition leaders| SpringVerify India"
      desc="Hire only the most qualified candidates with SpringVerify's robust screening solutions developed exclusively for Talent Acquisition teams"
      url="https://in.springverify.com/talent-acquisition/"
      imgurl="https://assets-sv-in-lander.s3.ap-south-1.amazonaws.com/LP/seo/roles/SV-IN+(Talent+Acquisition).jpeg"
    />
    <div className={styles.topSection}>
      <Header />
      <Banner
        h1="Background Verifications for Talent Acquisition Teams"
        p="Hire with confidence by screening candidates faster, easier, and more accurately with SpringVerify."
        img="https://assets-sv-in.gumlet.io/LP/talent-acquisition/talent-acquisition-banner.png"
      />
    </div>
    <BrandsTemp data={DataInfo.BrandsTemp} />
    <AllCheck data={DataInfo.TAAllCheck} />
    <SolvingVerify data={DataInfo.SolvingVerify} />
    <CustomizedSolutions
      h2={"Solutions built for \nTalent Acquisition teams"}
      solutions={solutions}
      path="Staffing"
    />
    <Explore data={DataInfo.Explore} />
    <Testimonials testimonials={testimonials} path="Address" />
    <GetStarted
      img="talent-acquisition/getstarted.svg"
      h3={`Ready to get started with \nthe best-in-class \nverification experience? `}
    />
    <Footer />
  </div>
);
